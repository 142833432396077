var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav',{staticClass:"mt-3",attrs:{"pills":"","vertical":""}},[_c('b-nav-item',{staticClass:"text-center"},[_c('b-img',{staticClass:"w-75 mb-3",attrs:{"src":require('@/assets/images/logo-rt-eletronica.png')}})],1),_c('router-link',{attrs:{"to":{ name: 'home' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"house-door"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.home"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: 'users' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var href = ref.href;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"people"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.users"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: 'cars' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var href = ref.href;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"cart"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.cars"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: 'drivers' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var href = ref.href;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"file-person"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.drivers"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: 'boxes' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var href = ref.href;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"box-seam"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.boxes"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: 'regionals' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var href = ref.href;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"house-door"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.regionals"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: 'destinations' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var href = ref.href;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"geo-alt"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.destinations"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: 'itineraries' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
        var href = ref.href;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          isExactActive && 'router-link-exact-active'
            ? 'active bg-danger text-white'
            : 'text-danger',
          'text-decoration-none',
          'nav-link'
        ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"map"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.itineraries"))+" ")],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }