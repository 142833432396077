
import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/layouts/NavBar.vue";
import HeaderAdmin from "@/components/layouts/HeaderAdmin.vue";
import ContentAdmin from "@/components/layouts/ContentAdmin.vue";

@Component({
  components: {
    NavBar,
    HeaderAdmin,
    ContentAdmin,
  },
})
export default class Admin extends Vue {}
