
import { Component, Vue } from "vue-property-decorator";
import User from "@/store/modules/User";
import { IUser } from "@/services/models";

@Component
export default class HeaderAdmin extends Vue {
  user?: IUser | null = null;

  created(): void {
    this.user = User.user;
  }

  mounted(): void {
    this.fullname();
  }

  fullname(): string {
    return `${this.user?.name} ${this.user?.surname}`;
  }

  logout(): void {
    User.logout();
    this.$router.push({ name: this.$routesNames.login });
  }
}
